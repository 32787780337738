import React from 'react'

export const Logo = ({ color = '#35261F' }) => (
  <svg width='205' height='54' viewBox='0 0 205 54' fill='none' xmlns='http://www.w3.org/2000/svg' className='media'>
    <path
      d='M141.32 26.1507V26.4617C145.595 27.0059 150.415 31.204 150.415 38.5894C150.415 47.0633 145.207 53.0494 133.623 53.0494H107.891V0.962402H133.545C142.952 0.962402 148.938 6.71529 148.938 15.4224C148.938 22.0304 144.429 25.6065 141.32 26.1507ZM118.464 9.98045V22.7301H131.68C135.878 22.7301 138.676 20.0869 138.676 16.0443C138.676 12.3904 135.8 9.98045 132.379 9.98045H118.464ZM118.464 44.0313H132.457C137.199 44.0313 139.609 41.4659 139.609 37.5788C139.609 33.614 136.811 31.1262 132.846 31.1262H118.464V44.0313Z'
      fill={ color }/>
    <path
      d='M156.84 0.962402H176.042C193.223 0.962402 204.651 11.4575 204.651 27.0059C204.651 42.5543 193.223 53.0494 176.042 53.0494H156.84V0.962402ZM167.413 43.4872H176.042C186.848 43.4872 194 37.1123 194 27.0059C194 16.8995 186.848 10.5246 176.042 10.5246H167.413V43.4872Z'
      fill={ color }/>
    <path
      d='M79.0458 44.7741L95.2481 40.4323L93.1265 32.5128L76.923 36.8546L91.4507 28.4669L87.3512 21.3679L72.8222 29.7569L84.6851 17.8939L78.8885 12.0961L67.0256 23.959L75.4133 9.43129L68.3143 5.33171L59.9265 19.8594L64.2684 3.65591L56.3502 1.53432L52.0083 17.7403V0.962402H43.8104V17.7403L39.4686 1.53432L31.5504 3.65591L35.8922 19.8607L27.5045 5.33171L20.4055 9.43129L28.7932 23.9603L16.9315 12.0973L11.1349 17.8952L22.9966 29.7569L8.46889 21.3691L4.36931 28.4694L18.8958 36.8571L2.69351 32.5128L0.571918 40.4323L16.7742 44.7741H0V52.972H29.9695V37.74C29.9695 37.263 30.2218 36.8209 30.6338 36.5799L46.4103 27.2969C46.8836 27.0184 47.468 27.0084 47.95 27.2706L65.1 36.5874C65.5333 36.8234 65.8043 37.2767 65.8043 37.7712V52.9732H95.8213V44.7753H79.0471L79.0458 44.7741Z'
      fill={ color }/>
  </svg>
)

export const DropdownArrow = ({ className, color = '#35261F' }) => (
  <svg className={ className } width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M4.99931 5.42747L5.4243 5.05747L9.4243 1.53747L8.5743 0.57248L4.99926 3.71744L1.42422 0.572479L0.574219 1.53747L4.57422 5.05747L4.99931 5.42747Z' fill={ color }/>
  </svg>
)

export const IconLogo = ({ color = '#35261F' }) => (
  <svg width='363' height='198' viewBox='0 0 363 198' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M299.453 166.078L360.833 149.63L352.796 119.628L291.411 136.076L346.447 104.301L330.917 77.4072L275.876 109.188L320.817 64.2466L298.857 42.2823L253.916 87.2232L285.692 32.1871L258.798 16.6565L227.023 71.6926L243.471 10.308L213.474 2.27063L197.026 63.6647V0.104004H165.969V63.6647L149.521 2.27063L119.524 10.308L135.972 71.6973L104.197 16.6565L77.3032 32.1871L109.079 87.228L64.1426 42.287L42.183 64.2513L87.1192 109.188L32.0831 77.4119L16.5525 104.31L71.5839 136.086L10.204 119.628L2.16663 149.63L63.5465 166.078H0V197.135H113.535V139.43C113.535 137.623 114.491 135.949 116.052 135.036L175.818 99.8682C177.611 98.8133 179.825 98.7754 181.651 99.7688L246.622 135.064C248.263 135.958 249.29 137.675 249.29 139.549V197.139H363.005V166.083H299.458L299.453 166.078Z'
      fill={ color }/>
  </svg>
)

export const RightLinkArrow = ({ color = '#35261F' }) => (
  <svg width='14' height='16' viewBox='0 0 14 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path id='Polygon 1' d='M14 7.99052L0.5 15.7847L0.500001 0.196289L14 7.99052Z' fill={ color }/>
  </svg>
)

export const LeftLinkArrow = ({ color = '#35261F' }) => (
  <svg width='14' height='16' viewBox='0 0 14 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path id='Polygon 2' d='M0 7.99052L13.5 15.7847L13.5 0.196289L0 7.99052Z' fill={ color }/>
  </svg>
)

export const UpArrow = ({ className, color = '#35261F' }) => (
  <svg className={ className } width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g id='icons/sml/chevron-up'>
      <path id='Vector' d='M7.99921 5.57248L7.57422 5.94248L3.57422 9.46248L4.42422 10.4275L7.99926 7.28251L11.5743 10.4275L12.4243 9.46248L8.4243 5.94248L7.99921 5.57248Z' fill={ color }/>
    </g>
  </svg>
)

export const DownLinkArrow = ({ className, color = '#35261F' }) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g id='icons/sml/arrows/down'>
      <path id='Polygon 2' d='M7.99999 14.0774L1.07178 2.07739L14.9282 2.07739L7.99999 14.0774Z' fill='#35261F'/>
    </g>
  </svg>
)

export const Location = ({ color = '#35261F' }) => (
  <svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g id='icons/sml/location'>
      <path id='Subtract' fillRule='evenodd' clipRule='evenodd'
            d='M8 3.92993C9.25486 3.92993 10.4201 4.49742 11.182 5.49849C12.2727 6.9326 12.2727 8.91959 11.182 10.3537L8 14.5065L4.81803 10.3537C3.72732 8.91959 3.72732 6.9326 4.81803 5.49849C5.57991 4.49742 6.74514 3.92993 8 3.92993ZM8.00027 9.53018C8.88392 9.53018 9.60027 8.81383 9.60027 7.93018C9.60027 7.04652 8.88392 6.33018 8.00027 6.33018C7.11661 6.33018 6.40027 7.04652 6.40027 7.93018C6.40027 8.81383 7.11661 9.53018 8.00027 9.53018Z'
            fill={ color }/>
    </g>
  </svg>
)
export const DoubleDots = ({ color = '#35261F' }) => (
  <svg width='96' height='18' viewBox='0 0 96 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='9' cy='9' r='9' fill={ color }/>
    <circle cx='87' cy='9' r='9' fill={ color }/>
  </svg>
)

export const LinkedIn = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path fillRule='evenodd' clipRule='evenodd'
          d='M2.00612 0C0.898172 0 0 0.898172 0 2.00612V17.9939C0 19.1018 0.898172 20 2.00612 20H17.9939C19.1018 20 20 19.1018 20 17.9939V2.00612C20 0.898172 19.1018 0 17.9939 0H2.00612ZM5.08489 6.6017H5.06675C4.15962 6.6017 3.5715 5.93407 3.5715 5.08862C3.5715 4.22589 4.17701 3.57143 5.10228 3.57143C6.02679 3.57143 6.59525 4.22425 6.6134 5.08615C6.6134 5.9316 6.02679 6.6017 5.08489 6.6017ZM3.89531 7.69366H6.40124V16.4272H3.89531V7.69366ZM13.5868 16.4287H16.4284V11.5717C16.4284 8.86745 15.0291 7.61039 13.2481 7.61039C11.8126 7.61039 10.913 8.46572 10.7467 9.06502V7.69436H7.93162C7.96791 8.42209 7.93162 16.4287 7.93162 16.4287H10.7467V11.7026C10.7467 11.6712 10.7466 11.6397 10.7464 11.6084V11.6083C10.7452 11.3774 10.7441 11.1541 10.808 10.9889C11.0015 10.4628 11.4173 9.9187 12.1649 9.9187C13.1423 9.9187 13.5868 10.7254 13.5868 11.9084V16.4287Z'
          fill='#35261F'/>
  </svg>
)

export const Facebook = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path fillRule='evenodd' clipRule='evenodd'
          d='M2.00612 0C0.898172 0 0 0.898172 0 2.00612V17.9939C0 19.1018 0.898172 20 2.00612 20H17.9939C19.1018 20 20 19.1018 20 17.9939V2.00612C20 0.898172 19.1018 0 17.9939 0H2.00612ZM10.6839 19.9999H14.0538L14.0533 11.9989H16.3019L16.6 9.24201H14.0533L14.0579 7.86206C14.0579 7.14305 14.1274 6.75779 15.1792 6.75779H16.5846V3.9999H14.3359C11.6342 3.9999 10.6839 5.33795 10.6839 7.58687V9.24201H9V11.9989H10.6839V19.9999Z'
          fill='#35261F'/>
  </svg>
)

export const Instagram = () => (
  <svg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path fillRule='evenodd' clipRule='evenodd'
          d='M5.82255 0H14.3518C17.5547 0 20.174 2.5868 20.174 5.74694V14.2531C20.174 17.414 17.5547 20 14.3518 20H5.82255C2.61959 20 0 17.414 0 14.2531V5.74694C0 2.5868 2.61956 0 5.82255 0ZM10.0327 4.59987C13.1451 4.59987 15.6709 7.12561 15.6709 10.2381C15.6709 13.3509 13.1451 15.8763 10.0327 15.8763C6.9194 15.8763 4.39403 13.3509 4.39403 10.2381C4.39403 7.12561 6.9194 4.59987 10.0327 4.59987ZM10.0326 6.50444C12.0937 6.50444 13.7664 8.17685 13.7664 10.2383C13.7664 12.2998 12.0936 13.9726 10.0326 13.9726C7.97072 13.9726 6.2983 12.2998 6.2983 10.2383C6.2983 8.17685 7.97072 6.50444 10.0326 6.50444ZM15.4873 3.81887C15.9929 3.81887 16.4028 4.22878 16.4028 4.73401C16.4028 5.23962 15.9929 5.64953 15.4873 5.64953C14.9821 5.64953 14.5722 5.23962 14.5722 4.73401C14.5722 4.22878 14.9821 3.81887 15.4873 3.81887ZM6.51942 1.63349H13.6555C16.335 1.63349 18.5264 3.81409 18.5264 6.47875V13.6505C18.5264 16.3151 16.335 18.4953 13.6555 18.4953H6.51942C3.83991 18.4953 1.64814 16.3151 1.64814 13.6505V6.47868C1.64814 3.81409 3.83991 1.63349 6.51942 1.63349Z'
          fill='#35261F'/>
  </svg>
)

export const Download = () => (
  <svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M8.06661 11.1092L12.4086 6.78017L11.6676 6.05217L8.57361 9.14617V2.30817H7.54661V9.13317L4.45261 6.03917L3.72461 6.76717L8.06661 11.1092Z' fill='#FFF1E2'/>
    <path fillRule='evenodd' clipRule='evenodd'
          d='M2.05714 11.3428C2.36484 11.3428 2.61429 11.5923 2.61429 11.9V13.3857C2.61429 13.632 2.71212 13.8681 2.88626 14.0423C3.0604 14.2164 3.29658 14.3143 3.54286 14.3143H12.4571C12.7034 14.3143 12.9396 14.2164 13.1137 14.0423C13.2879 13.8681 13.3857 13.632 13.3857 13.3857V11.9C13.3857 11.5923 13.6352 11.3428 13.9429 11.3428C14.2506 11.3428 14.5 11.5923 14.5 11.9V13.3857C14.5 13.9275 14.2848 14.4471 13.9017 14.8302C13.5186 15.2133 12.9989 15.4285 12.4571 15.4285H3.54286C3.00106 15.4285 2.48145 15.2133 2.09834 14.8302C1.71523 14.4471 1.5 13.9275 1.5 13.3857V11.9C1.5 11.5923 1.74944 11.3428 2.05714 11.3428Z'
          fill='#FFF1E2'/>
  </svg>
)

export const Play = () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="36" height="36" fill="white"/>
    <path d="M27 18.116L9 28.5083L9 7.72363L27 18.116Z" fill="#35261F"/>
  </svg>
)
