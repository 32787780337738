// in gastby-browser.js
exports.shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  if (!location.href.includes('#')) {
    window.scrollTo(0, 0)
    return false
  }
  // if changing pages and the location has a hash, scroll to the element after a timeout
  if (location.href.includes('#')) {
    const href = location.href.split('#')[1]
    const id = href.replace('#', '')
    const el = document.getElementById(id)
    if (el) {
      window.requestAnimationFrame(() => {
        el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
      })
      return false
    }
  }
}