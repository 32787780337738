import React, { Component } from 'react'
import Link from '../utils/link'
import { Logo, DropdownArrow, UpArrow, LeftLinkArrow, RightLinkArrow } from './icons'

class Header extends Component {

  state = {
    offCanvas: false,
    dropdown: false,
    dropdownIndex: null,
    homeBannerScrolled: false,
    scrolled: false, 
    direction: 0, 
    prevDirection: 0, 
    prevScroll: 0,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrollDetect)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollDetect)
  }

  onScroll = (e) => {
    let { direction, prevDirection, prevScroll, scrolled, homeBannerScrolled } = this.state

    const curScroll = window.scrollY || document.scrollTop
    scrolled = curScroll > 1

    let homeBanner = document.querySelector('.home-banner')
    let contactEl = document.querySelector('.contact')
    if (homeBanner || contactEl) homeBannerScrolled = curScroll > 345
    
    // Make sure they scroll more than 5px
    if (Math.abs(prevScroll - curScroll) <= 5) return
    direction = curScroll > prevScroll ? 2 : 1
    
    prevScroll = curScroll

    requestAnimationFrame(() => {
      this.setState({
        scrolled, direction, prevDirection, prevScroll, homeBannerScrolled
      })
    })
  }

  scrollDetect = () => {
    window.clearTimeout(this.isScrolling)
    this.isScrolling = setTimeout(this.onScroll, 0)
  }

  _hideOffCanvas = () => {
    this.setState({ 
      offCanvas: false,
      dropdown: false,
    })
  }

  _hideDropdown = () => {
    this.setState({
      offCanvas: false,
      dropdown: false,
      dropdownIndex: null,
    })
  }

  _toggleOffCanvas = () => {
    this.setState({ offCanvas: !this.state.offCanvas })
  }

  _toggleDropdown = (bool, i) => {
    this.setState({
      dropdown: bool,
      dropdownIndex: i,
    })
  }

  render() {

    let { offCanvas, dropdownIndex, dropdown, scrolled, direction, homeBannerScrolled } = this.state
    let { menu } = this.props.data.wp.siteSettings.acf

    let props = {
      onClick: this._hideOffCanvas,
      activeClassName: 'active',
    }
    let dropdownProps = {
      onClick: this._hideDropdown,
      activeClassName: 'active',
    }

    let headerClass = 'header'
    if (offCanvas) headerClass += ' header__off-canvas'
    if (dropdown) headerClass += ' header__modified'
    if (scrolled) headerClass += ' header--scrolled'
    if (homeBannerScrolled) headerClass += ' header--home-scrolled'
    headerClass += ` header--${ this.props.pageContext.header }`

    return (
      <>
        <header className={headerClass }>
          <div className='header__inner'>
            <Link to='/' title='BD Realty' className='header__logo' { ...props }>
              <Logo/>
            </Link>
            <nav className='header__nav'>
              { menu?.length > 0 &&
                <ul>
                  { menu.map((el, i) => (
                    <li key={ i } className={ `${ dropdownIndex === i ? 'active' : '' }` }
                        onMouseEnter={ () => this._toggleDropdown(el.subMenu?.length > 0, i) }
                        onMouseLeave={ () => this._toggleDropdown(false, null) }
                      >
                      <Link to={ el.link.url } { ...props }>
                        { el.link.title }
                        { el.subMenu?.length > 0 && <DropdownArrow className='dropdown-arrow'/> }
                        { el.subMenu?.length > 0 && <UpArrow className='up-arrow'/> }
                      </Link>
                      { el.subMenu?.length > 0 &&
                        <ul className='header__sub-nav'>
                          { el.subMenu.map((sub, s) => (
                            <li key={ s }>
                              <Link to={ sub.link.url } { ...dropdownProps }>{ sub.link.title }</Link>
                            </li>
                          )) }
                        </ul>
                      }
                    </li>
                  )) }
                </ul>
              }
            </nav>
            <button onClick={ offCanvas ? this._hideOffCanvas : this._toggleOffCanvas } className={ offCanvas ? 'header__hamburger active' : 'header__hamburger' }>
              <span className='lines'></span>
            </button>
          </div>
        </header>
        <div className={ `off-canvas ${ offCanvas && 'off-canvas--active' }` }>
          <div className='off-canvas__inner'>
            <Link className='btn' to='tel:+61482093762'>Call Us</Link>
            <nav className='off-canvas__nav'>
              { menu?.length > 0 &&
                <ul>
                  <li><Link to='/' { ...props }>Home</Link></li>
                  { menu.map((el, i) => (
                    <li key={ i } className={ `${ dropdownIndex === i ? 'active' : '' }` }>
                      { !el.subMenu?.length > 0 &&
                        <Link to={el.link.url} { ...props }>{ el.link.title }</Link>
                      }
                      { el.subMenu?.length > 0 && 
                        <button onClick={() => this._toggleDropdown(true, i) }>
                          { el.link.title }
                          { el.subMenu?.length > 0 && <RightLinkArrow /> }
                        </button>
                      }
                      { el.subMenu?.length > 0 &&
                        <ul className='off-canvas__sub-nav'>
                          <li>
                            <button onClick={() => this._toggleDropdown(false, null) }>
                              { el.subMenu?.length > 0 && <LeftLinkArrow /> }
                              { el.link.title }
                            </button>
                          </li>
                          { el.subMenu.map((sub, s) => (
                            <li key={ s }>
                              <Link to={ sub.link.url } { ...dropdownProps }>{ sub.link.title }</Link>
                            </li>
                          )) }
                        </ul>
                      }
                    </li>
                  )) }
                </ul>
              }
            </nav>
          </div>
        </div>
      </>
    )
  }
}

export default Header